import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"display":"flex","flex-grow":"1"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_dispute_transaction_screen = _resolveComponent("confirm-dispute-transaction-screen")!
  const _component_supplier_shipping_transaction_screen = _resolveComponent("supplier-shipping-transaction-screen")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      (!_ctx.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.state.transactionStatus >= _ctx.TransactionStatus.IN_TRANSIT && _ctx.state.transactionStatus != _ctx.TransactionStatus.DELETED)
              ? (_openBlock(), _createBlock(_component_confirm_dispute_transaction_screen, {
                  key: 0,
                  modelValue: _ctx.state.transaction,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.transaction) = $event))
                }, null, 8, ["modelValue"]))
              : (_openBlock(), _createBlock(_component_supplier_shipping_transaction_screen, {
                  key: 1,
                  modelValue: _ctx.state.transaction,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.transaction) = $event))
                }, null, 8, ["modelValue"]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.loading')) + ".. ", 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}