
import { defineComponent, onBeforeMount, reactive } from 'vue';
import Transaction from '@/domain/Transaction';
import { Direction } from '@/domain/TransactionAbstractShare';
import { TransactionStatus } from '@/domain/TransactionStatus';
import TransactionService from '@/services/TransactionService';
import SupplierShippingTransactionScreen from '@/modules/master-data/views/transactions/supplier-shipping/screens/SupplierShippingTransactionScreen.vue';
import coreStore from '@/store/CoreStore';
import ConfirmDisputeTransactionScreen from '@/modules/master-data/views/transactions/supplier-shipping/screens/ConfirmDisputeTransactionScreen.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    transaction: Transaction | null;
    loading: boolean;
    transactionType: Direction | null;
    transactionStatus: TransactionStatus | null;
};

export default defineComponent({
    name: 'master-data-transaction-edit-screen-dispatcher',
    components: {
        SupplierShippingTransactionScreen,
        ConfirmDisputeTransactionScreen,
    },
    props: {
        transactionId: {
            type: String,
            default: () => undefined,
            required: false,
        },
        transactionType: {
            type: String,
            default: () => undefined,
            required: false,
        },
    },
    setup(props) {
        const { profileStore } = coreStore.getInstance();
        const transactionService = new TransactionService();

        const state = reactive<State>({
            transaction: null,
            loading: true,
            transactionType: null,
            transactionStatus: null,
        });

        async function loadExisting(transactionId: number) {
            const response = await transactionService.getTransactionById(transactionId);
            if (response) {
                state.transaction = response.transaction;
                state.transactionType = state.transaction.getDirection(profileStore.userLocation);
                state.transactionStatus = state.transaction.status;
            }
        }

        function initInbound() {
            state.transaction = new Transaction();
            state.transaction.toLocation = profileStore.userLocation;
            state.transactionType = 'inbound'; // TODO: should this field go away and use getDirection()?
            state.transactionStatus = TransactionStatus.ORDERED;
        }

        function loadNew() {
            if (props.transactionType) {
                if (props.transactionType === 'inbound') {
                    initInbound();
                }
            }
        }

        onBeforeMount(async () => {
            state.loading = true;

            if (props.transactionId) {
                await loadExisting(parseInt(props.transactionId, 10));
            } else {
                loadNew();
            }

            state.loading = false;
        });

        return {
            getTitleCaseTranslation,
            state,
            TransactionStatus,
        };
    },
});
