import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_ctx.canDispute)
    ? (_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        disabled: _ctx.disabled,
        variant: "primary",
        onClick: _ctx.closeout
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.closeout')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]))
    : _createCommentVNode("", true)
}