
import { computed, defineComponent, PropType } from 'vue';
import { TransactionStatus } from '@/domain/TransactionStatus';
import Permissions from '@/services/permissions/Permissions';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'transaction-closeout-dispute-button',
    props: {
        disputing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        transactionStatus: {
            type: Number as PropType<TransactionStatus>,
            required: true,
        },
    },
    emits: ['closingOut'],
    setup(props, context) {
        function closeout() {
            context.emit('closingOut');
        }

        const canDispute = computed((): boolean => Permissions.ADMINISTRATION.canCloseoutDisputedTransactions() && !props.disputing && props.transactionStatus === TransactionStatus.DISPUTED);

        return {
            closeout,
            canDispute,
            getTitleCaseTranslation,
        };
    },
});
